import classNames from 'classnames';
import React, { RefObject } from 'react';
import { createPortal } from 'react-dom';
import ScrollLock from 'react-scrolllock';

import { YOUTUBE_VIDEO_ALLOW_OPTIONS } from '~/constants';
import { BrowserService } from '~/services/Browser';

import { ReactComponent as CloseIcon } from './assets/close-no-whitespace.svg';
import styles from './VideoPreview.module.scss';

interface DefaultProps {
  scrollable: boolean;
  closeOnOutsideClick: boolean;
}

export interface Props extends Partial<DefaultProps> {
  children?: React.ReactNode;
  show: boolean;
  className?: string;
  wrapperClassName?: string;
  onClose?: () => void;
  onShow?: () => void;
  videoUrl: string;
  title: string;
}

interface State {
  showModal: boolean;
  enter: boolean;
}

type PropsWithDefaults = Props & DefaultProps;

export class VideoPreview extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    scrollable: true,
    closeOnOutsideClick: true,
  };

  content: RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: props.show,
      enter: props.show,
    };
  }

  componentDidMount() {
    if (this.props.show) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const hasNewProp = nextProps.show !== this.props.show;

    if (hasNewProp) {
      if (nextProps.show) {
        this.setState(
          {
            showModal: nextProps.show,
          },
          this.handleAfterShow,
        );

        if (nextProps.onShow) {
          nextProps.onShow();
        }
      } else {
        this.handleClose();
      }
    }
  }

  handleAfterShow = () => {
    // Ensure that enter appears after 'show'
    // is set - push it to event loop queue
    setTimeout(() => {
      this.setState({
        enter: true,
      });
    }, 0);

    document.addEventListener('click', this.handleClickOutside, true);
  };

  handleClickOutside = (event: MouseEvent) => {
    const { closeOnOutsideClick } = this.props;

    if (closeOnOutsideClick) {
      const clickedOutside =
        this.content &&
        this.content.current &&
        !this.content.current.contains(event.target as Node);

      if (clickedOutside) {
        this.handleClose();
      }
    }
  };

  handleClose = () => {
    this.setState({ enter: false });
  };

  handleTransitionEnd = () => {
    if (!this.state.enter) {
      this.setState({ showModal: false });

      if (this.props.onClose && this.props.show) {
        this.props.onClose();
      }

      document.removeEventListener('click', this.handleClickOutside, true);
    }
  };

  render() {
    const { showModal, enter } = this.state;
    const {
      videoUrl,
      title,
      className,
      wrapperClassName,
      // scrollable,
    } = this.props as PropsWithDefaults;

    const classes = classNames(styles.root, className, {
      [styles.enter]: enter,
      // [styles.scrollable]: scrollable,
    });

    const wrapperClasses = classNames(styles.wrapper, wrapperClassName);
    const rootNode = BrowserService.rootNode;

    if (!showModal || !rootNode) {
      return null;
    }

    return createPortal(
      <ScrollLock>
        <div className={classes} onTransitionEnd={this.handleTransitionEnd}>
          <div className={wrapperClasses}>
            <div className={styles.content}>
              <button
                name='Close'
                aria-label='Close'
                type='button'
                className={styles.close}
                onClick={this.handleClose}
              >
                <CloseIcon className={styles.icon} />
              </button>

              <div className={styles.container} ref={this.content}>
                <iframe
                  title={title}
                  className={styles.video}
                  src={videoUrl}
                  allow={YOUTUBE_VIDEO_ALLOW_OPTIONS.join(',')}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollLock>,
      rootNode,
    );
  }
}
