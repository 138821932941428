import debounce from 'lodash/debounce';
import React, { RefObject } from 'react';

import { Breakpoint } from '~/constants';

import { VideoObject, VideoPreviewObject } from '~/hooks/useVideos';
import { BrowserService } from '~/services/Browser';

type Props = {
  className?: string;
  video: VideoObject;
  videoPreview: VideoPreviewObject;
};

enum Size {
  FULL_HD,
  HD,
  SD,
}

interface State {
  matchedWidth: Size;
}

interface VideoElementMethods {
  load(): void;
}

type ExtendedVideoType = HTMLVideoElement & VideoElementMethods;

export class Video extends React.Component<Props, State> {
  video: RefObject<ExtendedVideoType> = React.createRef();

  state: State = {
    matchedWidth: this.currentWindowWidth,
  };

  get currentWindowWidth() {
    const windowWidth = BrowserService.getWindowSize();

    if (windowWidth < Breakpoint.SMALL) {
      return Size.SD;
    } else if (windowWidth > Breakpoint.LARGE) {
      return Size.FULL_HD;
    }

    return Size.HD;
  }

  debouncedHandleResize = debounce(() => {
    this.setState({
      matchedWidth: this.currentWindowWidth,
    });
  }, 100);

  componentDidUpdate(prevProps: Readonly<Props>, prevState: State) {
    const didChange = prevState.matchedWidth !== this.state.matchedWidth;

    if (didChange && this.video.current) {
      this.video.current.load();
    }
  }

  componentDidMount() {
    BrowserService.resize.addResizeListener(this.debouncedHandleResize);
  }

  componentWillUnmount() {
    BrowserService.resize.removeResizeListener(this.debouncedHandleResize);
  }

  get videoUrl() {
    const { video480p, video720p, video1080p } = this.props.video;
    const { matchedWidth } = this.state;

    switch (matchedWidth) {
      case Size.FULL_HD:
        return video1080p.src;
      case Size.SD:
        return video480p.src;
      default:
        return video720p.src;
    }
  }

  get pictureUrl() {
    const { preview480p, preview720p, preview1080p } = this.props.videoPreview;
    const { matchedWidth } = this.state;

    switch (matchedWidth) {
      case Size.FULL_HD:
        return preview1080p.src;
      case Size.SD:
        return preview480p.src;
      default:
        return preview720p.src;
    }
  }

  render() {
    const { className } = this.props;

    return (
      <video
        ref={this.video}
        autoPlay
        muted
        loop
        playsInline
        poster={this.pictureUrl}
        className={className}
      >
        <source src={this.videoUrl} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    );
  }
}
