import { graphql, useStaticQuery } from 'gatsby';
import { PATH_STATIC } from '~/constants';

type Video = {
  width: number;
  height: number;
  presentationMaxWidth: number;
  presentationMaxHeight: number;
  src: string;
};

export type VideoObject = {
  video480p: Video;
  video720p: Video;
  video1080p: Video;
};

export type GetVideo = (path: string) => VideoObject | null;

type VideoPreview = {
  width: number;
  height: number;
  src: string;
};

export type VideoPreviewObject = {
  preview480p: VideoPreview;
  preview720p: VideoPreview;
  preview1080p: VideoPreview;
};

export type GetVideoPreview = (path: string) => VideoPreviewObject;

type QueryResult = {
  videos: {
    nodes: Array<{
      publicURL: string;
      name: string;
      childVideoFfmpeg: Pick<
        VideoObject,
        'video480p' | 'video720p' | 'video1080p'
      >;
    }>;
  };
  previews: {
    nodes: Array<VideoPreviewObject & { original: VideoPreview }>;
  };
};

const getDefaultPreview = (path: string): VideoPreviewObject => ({
  preview480p: {
    height: 0,
    width: 0,
    src: path,
  },
  preview720p: {
    height: 0,
    width: 0,
    src: path,
  },
  preview1080p: {
    height: 0,
    width: 0,
    src: path,
  },
});

const useVideos = () => {
  const queryResult: QueryResult = useStaticQuery(graphql`
    query {
      videos: allFile(filter: { ext: { in: [".mp4"] } }) {
        nodes {
          publicURL
          name
          childVideoFfmpeg {
            video480p: transcode(maxHeight: 480) {
              width
              height
              presentationMaxWidth
              presentationMaxHeight
              src
            }
            video720p: transcode(maxHeight: 720) {
              width
              height
              presentationMaxWidth
              presentationMaxHeight
              src
            }
            video1080p: transcode(maxHeight: 1080) {
              width
              height
              presentationMaxWidth
              presentationMaxHeight
              src
            }
          }
        }
      }
      previews: allImageSharp(filter: { original: { height: { gte: 1080 } } }) {
        nodes {
          original {
            src
          }
          preview480p: fixed(height: 480) {
            src
          }
          preview720p: fixed(height: 720) {
            src
          }
          preview1080p: fixed(height: 1080) {
            src
          }
        }
      }
    }
  `);

  const videos = queryResult.videos.nodes;

  const getVideo: GetVideo = (path) => {
    const video = videos.find((video) => {
      const src = video.publicURL
        .replace(`/${video.name}`, '')
        .replace(`${PATH_STATIC}/`, `${PATH_STATIC}/${video.name}-`);
      return src === path;
    });

    return video ? video.childVideoFfmpeg : null;
  };

  const previews = queryResult.previews.nodes;

  const getVideoPreview: GetVideoPreview = (path) => {
    const preview = previews.find((preview) => preview.original.src === path);
    return preview ? preview : getDefaultPreview(path);
  };

  return { getVideo, getVideoPreview };
};

export default useVideos;
